import request from '@/plugins/axios'
export function page(page, limit, obj) {
  return request({
    url: `/clubhouseUser/page/${page}/${limit}`,
    method: 'get',
    params: obj
  })
}

export function queryById(id) {
  return request({
    url: `/clubhouseUser/${id}`,
    method: 'get'
  })
}
