<template>
  <div class="index">
    <div class="index-head">
      <div class="head-item" v-for="(item, i) in headList" :key="i">
        <img :src="item.img" alt="" />
        <div class="text-box">
          <div v-if="!item.bold" class="item-text">
            {{ item.memo1 }}
          </div>
          <div v-else class="item-text">
            <span>{{ item.memo1 }}</span>
            {{ item.measurement }}
          </div>
          <div class="item-text">
            {{ item.memo2 }}
          </div>
        </div>
      </div>
    </div>
    <div class="index-content">
      <div class="content-left">
        <div class="index-title">俱乐部人员排行榜</div>
        <el-table
          :data="tableData"
          style="width: 100%"
          height="280px"
          :show-header="false"
        >
          <el-table-column width="50px">
            <template slot-scope="scope">
              <div class="left-table-cell">
                <div v-if="scope.$index >= 3" class="cell-index-next">
                  {{ scope.$index + 1 }}
                </div>
                <div v-else class="cell-index">
                  {{ scope.$index + 1 }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="50px">
            <template slot-scope="scope">
              <div class="left-table-cell">
                <img :src="scope.row.photoUrl" alt="" />
              </div>
            </template>
          </el-table-column>
          <el-table-column width="180px" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="left-table-cell">
                <span>
                  {{ scope.row.name }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="right" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="left-table-cell">
                <span class="person-number">
                  {{ scope.row.numMembers }}
                </span>
                &nbsp; 人
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="content-right">
        <div class="index-title">关键词</div>
        <div class="right-content">
          <div class="words-left">
            <ul>
              <li
                v-for="(item, i) in clubList"
                :key="i"
                @click="selectWords(item)"
              >
                <span :class="selectClub.id == item.id ? 'select-words' : ''">
                  {{ item.name }}
                </span>
              </li>
            </ul>
          </div>
          <div class="words-right">
            <!-- -->
            <div class="words-right-r">
              <div class="words-top">
                <div class="words-right-img">
                  <img :src="selectClub.photoUrl" alt="" />
                </div>
                <div class="info-box">
                  <div>
                    {{ selectClub.name }}
                  </div>
                  <div>ID：{{ selectClub.clubId }}</div>
                  <div>成员人数：{{ selectClub.numMembers }}</div>
                </div>
              </div>
              <div class="words-bottom">
                <div class="words-title">简介：</div>
                <div class="words-text">
                  {{ selectClub.description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="index-foot">
      <div class="index-foot-left">
        <div class="index-title">
          <span>俱乐部查询记录</span>
          <span class="data-more" @click="goRouter('/club/list')">更多>></span>
        </div>

        <el-table
          :data="footList"
          style="width: 100%"
          height="320px"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5px 0' }"
        >
          <el-table-column
            prop="id"
            label="俱乐部ID"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="头像" width="70">
            <template slot-scope="scope">
              <img :src="scope.row.photoUrl" alt="" class="club-img" />
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="名称"
            show-overflow-tooltip
            width="200"
          ></el-table-column>
          <el-table-column
            prop="description"
            label="简介"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="numMembers"
            label="成员人数"
            width="120"
          ></el-table-column>
        </el-table>
      </div>
      <div class="index-foot-right">
        <div class="index-title">
          <span>成员查询记录</span>
          <span class="data-more" @click="goRouter('/clubhouseUser/list')">
            更多>>
          </span>
        </div>
        <el-table
          :data="personList"
          style="width: 100%"
          height="320px"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5px 0' }"
        >
          <el-table-column label="头像" width="70">
            <template slot-scope="scope">
              <img :src="scope.row.photoUrl" alt="" class="club-img" />
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="用户姓名"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="username"
            label="用户名"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="numFollowers"
            label="粉丝数"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column prop="createTime" label="注册时间"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { getClubList } from '@/api/club.js'
import { page } from '@/api/clubhouseUser'
import { getDashboardData } from '@/api/dashboard.js'
export default {
  data() {
    return {
      headList: [
        {
          img: require('../../assets/imgs/t1.png'),
          memo1: '开通日期：2020-03-01',
          memo2: '开通日期：2020-03-01',
          bold: false
        },
        {
          img: require('../../assets/imgs/t2.png'),
          memo1: 'clubCount',
          memo2: 'club',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t3.png'),
          memo1: 'userCount',
          memo2: '用户',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t4.png'),
          memo1: 'clubUserCount',
          memo2: '俱乐部与成员',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t5.png'),
          memo1: 'invitedCount',
          memo2: '邀请信息',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t6.png'),
          memo1: 'twitterCount',
          memo2: 'twitter信息',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t2.png'),
          memo1: 'instagramCount',
          memo2: 'instagram信息',
          bold: true,
          measurement: ''
        }
      ],
      tableData: [],
      selectWordsIndex: 0,
      footList: [],
      personList: [],
      clubList: [],
      selectClub: {}
    }
  },
  created() {
    this.fetchData()
    this.getSortClubList()
    // 获取仪表盘数据
    this.getHead()
    this.getClubTable()
    this.getUserTable()
  },
  methods: {
    goRouter(path) {
      this.$router.push(path)
    },
    getClubTable() {
      getClubList({ pageIndex: 1, pageSize: 10 }).then(res => {
        this.footList = res.items
      })
    },
    getUserTable() {
      page(1, 10).then(resp => {
        this.personList = resp.items
      })
    },
    getHead() {
      getDashboardData().then(res => {
        this.headList[0].memo1 =
          '开通日期：' +
          this.$store.state.user.userInfo.create_time.substring(0, 10)
        this.headList[0].memo2 =
          '结束日期：' +
          this.$store.state.user.userInfo.endTime.substring(0, 10)
        this.headList[1].memo1 = res.clubCount
        this.headList[2].memo1 = res.userCount
        this.headList[3].memo1 = res.clubUserCount
        this.headList[4].memo1 = res.invitedCount
        this.headList[5].memo1 = res.twitterCount
        this.headList[6].memo1 = res.instagramCount
      })
    },
    selectWords(row) {
      this.selectClub = Object.assign({}, row)
    },
    async fetchData() {
      this.listLoading = true
      try {
        const { items } = await getClubList({
          pageIndex: 1,
          pageSize: 8,
          name: '中国'
        })
        // this.count = count
        this.clubList = items
        if (this.clubList.length > 0) {
          this.selectClub = Object.assign({}, this.clubList[0])
        }
      } finally {
        // this.listLoading = false
      }
    },
    // 获取排序的club列表
    async getSortClubList() {
      const data = await getClubList({
        pageIndex: 1,
        pageSize: 5,
        order: 'num_members'
      })
      this.tableData = data.items
    }
  }
}
</script>

<style lang="scss" scoped>
.index {
  width: 100%;
  height: calc(100vh - 50px);
  padding: 10px 20px;
  background: #f2f4f8;
  .index-head {
    width: 100%;
    background: white;
    border-radius: 5px;
    padding: 5px 0;
    display: flex;
    flex-wrap: nowrap;
    box-shadow: 0px 1px 3px 0px #d8d9d9;
    .head-item {
      width: calc(100% / 7 - 1px);
      height: 131px;
      border-right: 1px solid #f2f4f8;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      &:nth-last-child(1) {
        border-right: none;
      }
      img {
        object-fit: cover;
        align-self: center;
      }
      .text-box {
        align-self: center;
        margin-top: 14px;
        text-align: center;
        .item-text {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          span {
            font-size: 20px;
          }
        }
      }
    }
  }
  .index-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 337px;
    margin-top: 10px;
    overflow: hidden;
    .content-left {
      width: 412px;
      height: 100%;
      background: #ffffff;

      box-shadow: 0 1px 3px 0 #d8d9d9;
      border-radius: 5px;
      padding: 10px;
      .left-table-cell {
        display: flex;
        > * {
          align-self: center;
        }
        .cell-index {
          width: 30px;
          height: 30px;
          border: 3px solid #ff9813;
          border-radius: 50%;
          font-size: 14px;
          text-align: center;
          font-weight: bold;
          color: #444444;
          line-height: 25px;
        }
        .cell-index-next {
          width: 30px;
          height: 30px;
          border: 3px solid #8fbdfd;
          border-radius: 50%;
          font-size: 14px;
          text-align: center;
          font-weight: bold;
          color: #444444;
          line-height: 25px;
        }
        img {
          width: 30px;
          height: 30px;
          object-fit: cover;
          border-radius: 5px;
        }
        span {
          font-size: 14px;
          font-weight: 400;
          color: #444444;
        }
        .person-number {
          font-size: 14px;
          font-weight: 300;
          color: #ff9713;
        }
      }
    }
    .content-right {
      width: calc(100% - 420px);
      padding: 10px;
      height: 100%;
      background: #ffffff;
      border: 0px solid #000000;
      box-shadow: 0px 1px 3px 0px #d8d9d9;
      border-radius: 5px;
      .right-content {
        width: 100%;
        height: calc(100% - 30px);
        display: flex;
        justify-content: space-between;
        .words-left {
          width: 177px;
          height: 100%;
          padding: 5px 0;
          overflow: hidden;
          ul {
            padding: 0;
            margin: 0;
            height: 100%;
            overflow: hidden;
            overflow-y: auto;
            li {
              list-style: none;
              margin-bottom: 15px;
              cursor: pointer;

              span {
                padding: 5px 0;
                font-size: 14px;
                font-weight: 400;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .select-words {
                color: #ff9713;
                border-bottom: 1px solid #ff9713;
              }
              &:hover {
                color: #ff9713;
              }
            }
          }
        }
        .words-right {
          width: calc(100% - 185px);
          height: calc(100% - 15px);
          background: #fefefe;
          margin: 10px;
          margin-right: 0;

          .words-right-r {
            height: 100%;
            width: calc(100%);
            padding: 5px;
            // background: #4444447e;
            > div {
              height: 49%;
            }
            .words-top {
              display: flex;

              .words-right-img {
                img {
                  width: 54px;
                  height: 54px;
                  object-fit: cover;
                  border-radius: 5px;
                  margin: 10px 25px 15px 10px;
                }
              }
              .info-box {
                > div {
                  font-size: 15px;
                  font-weight: 400;
                  color: #333333;
                  line-height: 30px;
                }
              }
              border-bottom: 1px solid #eceaea;
            }
            .words-bottom {
              display: flex;
              .words-title {
                text-align: center;
                width: 100px;
                font-size: 15px;
                font-weight: 600;
                color: #333333;
                line-height: 50px;
              }
              .words-text {
                width: 100%;
                padding: 10px 0;
                overflow: hidden;
                font-size: 14px;
                font-weight: 400;
                color: #686868;
                line-height: 28px;
                overflow: auto;
                // 表格滚动条

                overflow-y: auto;
                &::-webkit-scrollbar {
                  width: 7px;
                }
                &::-webkit-scrollbar-thumb {
                  background: #d8d8d8;
                  border-radius: 10px;
                }
                &::-webkit-scrollbar-track-piece {
                  background: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
  .index-foot {
    margin-top: 10px;
    width: 100%;
    height: 380px;
    // background: #666666;
    display: flex;
    justify-content: space-between;
    .index-foot-left {
      width: 57%;
      padding: 10px;
      height: 96%;
      background: #ffffff;
      border: 0px solid #000000;
      box-shadow: 0 1px 3px 0 #d8d9d9;
      border-radius: 5px;
      position: relative;
    }
    .index-foot-right {
      width: calc(43% - 5px);
      padding: 10px;
      height: 96%;
      background: #ffffff;
      border: 0px solid #000000;
      box-shadow: 0 1px 3px 0 #d8d9d9;
      border-radius: 5px;
    }
    .club-img {
      width: 30px;
      height: 30px;
      object-fit: cover;
      border-radius: 5px;
      vertical-align: middle;
    }
  }
  .index-title {
    font-size: 14px;
    font-weight: 400;
    color: #4a4a4a;
    border-bottom: 1px solid #f4f4f4;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    height: 30px;
    text-indent: 9px;
    .data-more {
      font-size: 12px;
      color: #918f8f;
      cursor: pointer;
      &:hover {
        color: rgb(38, 81, 178);
      }
    }
  }
  ::v-deep .el-table__header .el-table__cell {
    background: rgb(243, 247, 249);
    height: 35px;
    padding: 0;
  }
  .el-table::before {
    background: none;
  }
}
</style>
