import request from '@/plugins/axios'

export function getClubList(data) {
  // 获取俱乐部的列表
  return request({
    url: '/clubs',
    method: 'get',
    params: data
  })
}

export function getClubDetail(clubId) {
  // 获取俱乐部的详情
  return request({
    url: '/clubs/' + clubId,
    method: 'get'
  })
}

export function getClubMembers(clubId, data) {
  // 获取俱乐部成员
  return request({
    url: '/clubUsers/' + clubId,
    method: 'get',
    params: data
  })
}
